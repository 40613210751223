.menu_button {
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  box-shadow: none;
  border: none;
  background: none;

  cursor: pointer;
}

.icon {
  &:before,
  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 22px;
    background-color: var(--color);
    transition: transform 0.15s ease;
    border-radius: 99999px;
  }

  &:before {
    transform: translateY(-4px) rotate(0deg);
  }

  &:after {
    transform: translateY(4px) rotate(0deg);
  }

  &.open {
    &:before {
      transform: translateY(1px) rotate(45deg);
    }

    &:after {
      transform: translateY(-1px) rotate(-45deg);
    }
  }
}

.side_menu {
  position: relative;

  &:not(.open) {
    display: none;
  }
}
