.search_button {
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: none;
  box-shadow: none;
  border: none;
  background: none;

  color: var(--color);

  cursor: pointer;
}
