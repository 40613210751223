.notification_line {
  display: block;
  background: rgba(247, 165, 1, 1);
  font-family: var(--font);
  font-size: 16px;
  color: black;
  position: relative;
  z-index: 30;
}

.container {
  max-width: var(--container-max-width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  //text-align: center;

  padding: 6px 8px;

  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  @media (min-width: 769px) {
    justify-content: center;
  }
}

.text {
  line-height: 100%;

  b {
    font-weight: 600;
  }

  @media (min-width: 769px) {
    margin-right: 10px;
  }
}

.timer {
  opacity: 0.7;

  font-weight: 500;
  font-variant: tabular-nums;

  font-size: 15px;

  margin-left: auto;

  @media (min-width: 769px) {
    margin-left: 40px;
  }
}

.button {
  flex-shrink: 0;
  border: unset;
  background: unset;
  outline: unset;

  border-radius: 8px;
  font-size: 16px;

  padding: 4px 6px;

  font-family: var(--font);
  //margin-left: 10px;

  background: white;
  color: black;

  font-weight: 500;

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  //margin-left: auto;
}
